import React, { useCallback, useEffect, useState } from "react";
import arrow_left from "../../../../assets/arrow-left-line.png";
import "./money-transfer.css";
import { getNames } from "country-list";
import Button from "../../../../utilities/button/Button";
import NavBar from "../../../../utilities/navbar/NavBar";
import { Outlet, useNavigate } from "react-router-dom";
import { useContextProvider } from "../../../../services/context_provider";
import {
  getCurrencyConvert,
  sendMoney,
} from "../../../../services/money_transfer";
import debounce from "lodash.debounce";
import toast from "react-hot-toast";
import lkr_flag from "../../../../assets/Ellipse 182.png";
import usd_flag from "../../../../assets/Ellipse 181.png";

export default function MoneyTransfer() {
  const { contextData, setContextData } = useContextProvider<any>();
  const navigate = useNavigate();
  const [countries, setCountries] = useState<any>([]);
  const [covertedCurrencyData, setCovertedCurrencyData] = useState<any>();
  const [error, setError] = useState<string | null>(null);
  const [moneyTrnsferData, setMoneyTransferData] = useState({
    card_id:
      contextData?.transferData !== undefined
        ? contextData?.transferData?.card_id
        : "",
    from_currency_code:
      contextData?.transferData?.from_currency_code !== undefined
        ? contextData?.transferData?.from_currency_code
        : "USD",
    to_currency_code:
      contextData?.transferData?.to_currency_code !== undefined
        ? contextData?.transferData?.to_currency_code
        : "LKR",
    amount:
      contextData?.transferData?.amount !== undefined
        ? contextData?.transferData?.amount
        : 10,
    converted_amount: "",
    transaction_narration:
      contextData?.transferData?.transaction_narration !== undefined
        ? contextData?.transferData?.transaction_narration
        : "",
  });
  const [moneyConvertLoading, setMoneyConvertLoading] = useState(false);
  const [defaultCardDetails,setDefaultCardDetails] = useState<any>()

  useEffect(() => {
    const countryList = getNames();
    setCountries(countryList);
    console.log("Transfer Details:", contextData?.cardDetails);
    const defaultCard = contextData?.cardDetails.find((card: any) => card.is_default);
    setMoneyTransferData((prevState) => ({
      ...prevState,
      card_id: defaultCard?.creditcard_id, // Replace "new-card-id" with the actual value you want to set
    }));
    setDefaultCardDetails(defaultCard)
    if (contextData === undefined) {
      navigate(`/transaction-history`, {
        state: { email: localStorage.getItem("userEmail") },
      });
    }
  }, [contextData]);

  const convertSendAmount = useCallback(
    debounce(async (amount, to_currency_code, from_currency_code) => {
      try {
        setError(null);
        setMoneyConvertLoading(true);
        const moneyConvertRes = await getCurrencyConvert(
          amount,
          from_currency_code,
          to_currency_code
        );
        if (moneyConvertRes.code === 100) {
          console.log(moneyConvertRes);
          setCovertedCurrencyData(moneyConvertRes?.data);
          setMoneyTransferData((prevState) => ({
            ...prevState,
            converted_amount: moneyConvertRes?.data?.original_amount, // Replace "new-card-id" with the actual value you want to set
          }));
          setMoneyConvertLoading(false);
        }
      } catch (error) {
        console.error("Error converting amount:", error);
      } finally {
      }
    }, 1000),
    []
  );
  const goToChild = () => {
    console.log("Select your card : ",moneyTrnsferData?.card_id)
    if (moneyTrnsferData?.card_id === undefined) {
      toast.error("Select your card");
      return;
    }
    if (moneyTrnsferData?.amount < 5) {
      setError("Minimum transaction amount must be 5$");
      return;
    }
    if (moneyTrnsferData?.amount > 2500) {
      setError("Maximum transaction amount must be 2500$");
      return;
    }
    if (moneyTrnsferData?.amount === "0") {
      toast.error("Transfer amount can't be zero");
      return;
    }
    if (moneyTrnsferData?.converted_amount === "0") {
      toast.error("Transfer amount can't be zero");
      return;
    }
    setContextData((prevState: any) => ({
      ...prevState,
      transferData: moneyTrnsferData,
    }));
    navigate(`/set-favourite`);
  };
  useEffect(() => {
    // if (moneyTrnsferData?.amount != 0) {
    //   convertSendAmount(
    //     moneyTrnsferData?.amount,
    //     moneyTrnsferData?.to_currency_code,
    //     moneyTrnsferData?.from_currency_code
    //   );
    // }
    if (moneyTrnsferData?.amount < 5) {
      setError("Minimum transaction amount must be 5$");
      return;
    }
    if (moneyTrnsferData?.amount > 2500) {
      setError("Maximum transaction amount must be 2500$");
      return;
    }
    convertSendAmount(
      moneyTrnsferData?.amount,
      moneyTrnsferData?.to_currency_code,
      moneyTrnsferData?.from_currency_code
    );
  }, [
    moneyTrnsferData?.amount,
    moneyTrnsferData?.to_currency_code,
    moneyTrnsferData?.from_currency_code,
  ]);

  const handleSelectCardHandle = (e: any) => {
    if (e.target.value === "add") {
    }
    console.log(e.target.value);
    setMoneyTransferData((prevState) => ({
      ...prevState,
      card_id: e.target.value, // Replace "new-card-id" with the actual value you want to set
    }));
  };
  const handleFromCurrencyCodeHandle = (e: any) => {
    console.log(e.target.value);
    setMoneyTransferData((prevState) => ({
      ...prevState,
      from_currency_code: e.target.value, // Replace "new-card-id" with the actual value you want to set
    }));
  };

  const handleToCurrencyCodeHandle = (e: any) => {
    console.log(e.target.value);
    setMoneyTransferData((prevState) => ({
      ...prevState,
      to_currency_code: e.target.value, // Replace "new-card-id" with the actual value you want to set
    }));
  };
  const handleSendAmountHandle = (e: any) => {
    console.log(e.target.value);
    setMoneyTransferData((prevState) => ({
      ...prevState,
      amount: e.target.value, // Replace "new-card-id" with the actual value you want to set
    }));
  };

  const handleConvertedAmountHandle = (e: any) => {
    console.log(e.target.value);
    setMoneyTransferData((prevState) => ({
      ...prevState,
      converted_amount: e.target.value, // Replace "new-card-id" with the actual value you want to set
    }));
  };
  const handleNoteTextHandle = (e: any) => {
    console.log(e.target.value);
    setMoneyTransferData((prevState) => ({
      ...prevState,
      transaction_narration: e.target.value, // Replace "new-card-id" with the actual value you want to set
    }));
  };

  return (
    <div className="main-money-transfer-div">
      <NavBar verifiedEmail="" />
      <div className="d-flex justify-content-center align-items-center pt-4">
        <div className="money-transfer-main-div">
          <div className="d-flex justify-content-start align-items-center mb-4 vw-100 arrow-left">
            <img
              src={arrow_left}
              alt=""
              width={24}
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="transfer-topic">Transfer money</div>
          <div className="vw-100">
            <div className="label-text">Select card</div>
            <select
              value={moneyTrnsferData?.card_id || defaultCardDetails?.creditcard_id}
              onChange={handleSelectCardHandle}
              name="bot_token"
              className="div-type-input pl-2 vw-100"
            >
              <option value="">Select your card</option>
              {/* <option value="add">+ Add new card</option> */}
              {contextData?.cardDetails.map((card: any, index: any) => (
                <option
                  key={card.creditcard_id}
                  className="pt-2 pb-1 vw-100"
                  value={card.creditcard_id || defaultCardDetails?.creditcard_id}
                >
                  {card?.card_number}
                  {"-"}
                  {card?.card_nick_name}
                </option>
              ))}
            </select>
            {contextData?.cardDetails.length === 0 ? (
              <div className="formik-errors">Please add a card</div>
            ) : null}
          </div>
          <div className="vw-100">
            <div className="label-text">Send amount</div>
            <div className="div-type-input-money">
              <div className="d-flex justify-content-start align-items-center">
                {/* <span>
                  {moneyTrnsferData?.from_currency_code === "USD" ? (
                    <img src={usd_flag} width={24} alt="" />
                  ) : (
                    <img src={lkr_flag} width={24} alt="" />
                  )}
                </span>
                <select
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    border: "none",
                    width: "100%",
                    outline: "none",
                    backgroundColor: "#F8F8F8",
                  }}
                  value={moneyTrnsferData?.from_currency_code}
                  onChange={handleFromCurrencyCodeHandle}
                  name="bot_token"
                  className=""
                >
                  <option className="pt-2 pb-1" value="USD">
                    USD
                  </option>
                  <option className="pt-2 pb-1" value="LKR">
                    LKR
                  </option>
                </select> */}
                <img src={usd_flag} width={24} alt="" className="mr-2" />
                <div
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    border: "none",
                    width: "100%",
                    outline: "none",
                    backgroundColor: "#F8F8F8",
                  }}
                >
                  USD
                </div>
              </div>
              {/* {formik.touched.gender && formik.errors.gender ? (
                <div className="formik-errors">{formik.errors.gender}</div>
              ) : null} */}
              {/* <div className="send-vale-text">1000</div> */}
              <input
                placeholder="Amount"
                className="send-vale-text"
                type="number"
                style={{
                  border: "none",
                  width: "50%",
                  outline: "none",
                  textAlign: "end",
                  backgroundColor: "#F8F8F8",
                }}
                value={moneyTrnsferData?.amount}
                onChange={handleSendAmountHandle}
                inputMode="decimal"
                pattern="[0-9]*[.,]?[0-9]*"
              />
            </div>
          </div>
          <div className="formik-errors vw-100" style={{ textAlign: "start" }}>
            {error}
          </div>
          <div className="d-flex justify-content-between align-items-center vw-100">
            <div className="details-text">Card fees</div>
            <div className="details-text-value">
              {covertedCurrencyData?.platform_charge
                ? covertedCurrencyData?.platform_charge
                : "0"}{" "}
              USD
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center vw-100">
            <div className="details-text">Instant transfer fee</div>
            <div className="details-text-value">
              {covertedCurrencyData?.instant_charge
                ? covertedCurrencyData?.instant_charge
                : "0"}{" "}
              USD
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center vw-100">
            <div className="details-text">Total fees</div>
            <div className="details-text-value">
              {covertedCurrencyData?.platform_charge &&
              covertedCurrencyData?.platform_charge
                ? covertedCurrencyData?.platform_charge +
                  covertedCurrencyData?.instant_charge
                : "0"}{" "}
              USD
            </div>
          </div>
          <div className="devider"></div>
          <div className="d-flex justify-content-between align-items-center vw-100">
            <div className="details-text">Exchange rate</div>
            <div className="details-text-value">
              {covertedCurrencyData?.usd_rate
                ? covertedCurrencyData?.usd_rate
                : "0"}{" "}
              LKR
            </div>
          </div>
          <div className="vw-100 mb-3">
            <div className="label-text">Recipient get</div>
            <div className="div-type-input-money">
              <div className="d-flex justify-content-start align-items-center">
                {/* <span>
                 {moneyTrnsferData?.to_currency_code === "USD" ? (
                    <img src={usd_flag} width={24} alt="" />
                  ) : (
                    <img src={lkr_flag} width={24} alt="" />
                  )}
                </span> */}
                <img src={lkr_flag} width={24} alt="" className="mr-2" />
                <div
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    border: "none",
                    width: "100%",
                    outline: "none",
                    backgroundColor: "#F8F8F8",
                  }}
                >
                  LKR
                </div>
                {/* <select
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    border: "none",
                    width: "100%",
                    outline: "none",
                    backgroundColor: "#F8F8F8",
                  }}
                  value={moneyTrnsferData?.to_currency_code}
                  onChange={handleToCurrencyCodeHandle}
                  name="bot_token"
                  className=""
                >
                  <option className="pt-2 pb-1" value="USD">
                    USD
                  </option>
                  <option className="pt-2 pb-1" value="LKR">
                    LKR
                  </option>
                </select> */}
                {/* {formik.touched.gender && formik.errors.gender ? (
                <div className="formik-errors">{formik.errors.gender}</div>
              ) : null} */}
              </div>
              <div className="send-vale-text">
                {moneyConvertLoading ? (
                  <>
                    <div className="details-text">Converting...</div>
                  </>
                ) : (
                  <>
                    {covertedCurrencyData?.converted_amount_receiver_amount_lkr
                      ? covertedCurrencyData?.converted_amount_receiver_amount_lkr.toFixed(
                          2
                        )
                      : "0"}
                  </>
                )}
              </div>
            </div>
          </div>
          {/* <div className="vw-100">
            <div className="label-text">Note</div>
            <textarea
              className="div-type-input-textarea"
              id="comments"
              name="comments"
              rows={4}
              placeholder="Write a note"
              value={moneyTrnsferData?.transaction_narration}
              onChange={handleNoteTextHandle}
            ></textarea>
          </div> */}
          <Button
            label="Continue"
            type="submit"
            onClick={goToChild}
            className={`continue-button ${
              moneyConvertLoading || error !== null ? "disabled-area" : ""
            }`}
            //   loading={passwordSetLoading}
          />
        </div>
      </div>
    </div>
  );
}
